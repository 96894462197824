import { HttpClient, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuModel } from './../../models/menu.model';
import * as bootstrap from 'bootstrap';
import * as $ from 'jquery';

import {
  Component,
  OnInit,
  ViewEncapsulation,
  AfterViewInit,
  NgZone,
  EventEmitter,
  Output,
  ViewChildren,
  QueryList,
  ElementRef,
  ViewChild,
  HostBinding,
  inject,
} from '@angular/core';
import { Endpoint, Group, Unicode } from 'src/shared/Endpoint';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { DatePipe } from '@angular/common';
import { UserModel } from '../../models/mongo/user.model';
import { GCPProjectModel } from 'src/models/mongo/gcp_project.model';
import { NewProjectModalComponent } from 'src/shared-components/new-project-modal/new-project-modal.component';
import { GwsGcpPopupComponent } from 'src/shared-components/gws-gcp-popup/gws-gcp-popup.component';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageService } from '../../servcies/message.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { OpportunityService } from 'projects/opportunity/src/public-api';

@Component({
  selector: 'app-navigation',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit, AfterViewInit {
  @HostBinding('class') class = 'app_nav_page';

  @ViewChildren('popoverTriggerEl') popoverTriggerEls!: QueryList<ElementRef>;
  @ViewChild('new_project_modal') newProjectModal: NewProjectModalComponent;
  currentUser: UserModel;
  imageUrl: string;
  isLoggedIn: boolean = false;
  pendingOrderCount: number = 1;
  menus: MenuModel[] = [];
  isMenuOpened: boolean;
  canCreateUser: boolean = false;
  newProjectModel: GCPProjectModel = new GCPProjectModel();
  fab1: HTMLElement;
  innerFabs: Element;
  createProjectClicked: boolean = false;
  @ViewChild('gwsGcpDealModal')
  gwsGcpModal: GwsGcpPopupComponent;
  buttonsActions = {
    createOrder: false,
    createDeal: false,
    createOpp: false,
    createSOW: false,
    createPOC: false,
    createProposal: false,
    createCustReq: false,
    createEngRecom: false,
  };
  count: Number;
  @ViewChildren('fab_item') fabButtonsElements: QueryList<ElementRef>;

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  /** GCP MODULES : CUST REQ ; ENG RECOM ; SOW ;POC ; PROPOSAL **/
  canCreateGCPModules: boolean;
  canCreateGWSModules: boolean;
  canStartOpportunity: boolean;

  opportunityService = inject(OpportunityService);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private http: HttpClient,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer,
    private messageService: MessageService
  ) {
    this.messageService.getDropdownValue().subscribe((newValue) => {
      this.count = newValue;
    });
    this.newProjectModel.organizationName = Endpoint.ORGANISATION_NAME;
    this.pendingOrderCount = 1;
    this.currentUser = this.localStorage.getLoggedinUserModel();
    this.canCreateGCPModules =
      this.currentUser.isAdmin ||
      this.currentUser.isFinanceTeam ||
      (this.currentUser.isEngineer && this.currentUser.is_gcp);
    this.canCreateGWSModules =
      this.currentUser.isSales ||
      this.currentUser.isOperations ||
      this.currentUser.isFinanceTeam ||
      this.currentUser.isAdmin;
    this.canStartOpportunity =
      this.currentUser.isSales ||
      this.currentUser.isAdmin ||
      this.currentUser.isFinanceTeam;
    var stg_mnus = this.localStorage.get(Unicode.MENUS);
    if (stg_mnus) {
      this.menus = JSON.parse(stg_mnus);
      this.menus = this.menus.sort((n1, n2) => n1.order - n2.order);
    }
    this.isLoggedIn = this.currentUser != null;
    if (this.menus && this.menus.find((x) => x.name.toLowerCase() == 'users'))
      this.canCreateUser = true;
    else this.canCreateUser = false;
  }

  ngOnInit(): void {
    this.createProjectClicked = false;
    this.fab1 = document.getElementById('fab1');
    this.innerFabs = document.getElementsByClassName('inner-fabs')[0];
    this.countUnreadNotifications();
  }
  openNav() {
    this.isMenuOpened = !this.isMenuOpened;
  }

  ngAfterViewInit(): void {
    this.handleCollapse();
    this.handleAdd();
  }

  logout() {
    this.localStorage.logout();
    this.router.navigate(['login']);
  }
  menuClick(menuId) {
    this.isMenuOpened = false;
  }

  addUser() {
    this.router.navigate(['/users/user-details'], {
      state: { model: JSON.stringify(new UserModel()) },
    });
  }

  handleCollapse() {
    let nav = document.getElementById('sidebar');
    let hamburgerBtn = document.getElementById('hamburger-btn');
    hamburgerBtn.addEventListener('click', () =>
      nav.classList.toggle('toggled')
    );
    hamburgerBtn.removeEventListener('click', () =>
      nav.classList.toggle('toggled')
    );
  }

  handleAdd() {
    const popoverTriggerList = document.querySelectorAll(
      '[data-bs-toggle="popover"]'
    );
    const popoverList = Array.from(popoverTriggerList).map(
      (popoverTriggerEl) => new bootstrap.Popover(popoverTriggerEl)
    );

    document
      .getElementById(
        `${window.location.pathname.split('.html')[0].split('/')[1]}-menu`
      )
      ?.classList?.toggle('active');

    screen.width < 768 &&
      (document.getElementById('dashboard-menu').style.borderTopRightRadius =
        '15px');
  }

  createCustomer() {
    this.opportunityService.opportunity.set(null);
    this.router.navigate(['customers/customer-create'], {
      queryParams: { isCreationOrTransfer: true },
      // skipLocationChange: true,
    });
  }
  transferCustomer() {
    this.opportunityService.opportunity.set(null);
    this.router.navigate(['customers/customer-create'], {
      queryParams: { isCreationOrTransfer: false },
      // skipLocationChange: true,
    });
  }

  createDeal() {
    this.buttonsActions = {
      createOrder: false,
      createDeal: true,
      createOpp: false,
      createSOW: false,
      createPOC: false,
      createProposal: false,
      createCustReq: false,
      createEngRecom: false,
    };
    this.gwsGcpModal.showPopup();
  }

  createOrder() {
    this.router.navigate(['orders/add-order'], {
      queryParams: { isGWS: true },
    });
  }

  async AddNewSale() {
    this.router.navigate(['/users/user-details'], {
      state: { model: JSON.stringify(new UserModel()) },
    });
  }
  settings() {
    this.router.navigate(['settings']);
  }

  createBillingAccount() {
    this.opportunityService.opportunity.set(null);
    this.router.navigate(['customers/customer-create'], {
      queryParams: {
        billingAccountCase: true,
      },
      skipLocationChange: true,
    });
  }

  onMouseEnter() {
    if (this.fab1.classList.contains('fab-toggled')) {
      this.innerFabs.classList.remove('show');
      if (this.fabButtonsElements)
        this.fabButtonsElements.forEach((element) => {
          element.nativeElement.classList.remove('z-10');
        });
      this.fab1.classList.remove('fab-toggled');
      (document.querySelector('#fabIcon').children[0] as HTMLImageElement).src =
        './assets/img/fab-icon.svg';
    } else {
      this.innerFabs.classList.add('show');
      if (this.fabButtonsElements)
        this.fabButtonsElements.forEach((element) => {
          element.nativeElement.classList.add('z-10');
        });
      this.fab1.classList.add('fab-toggled');
      if (this.fab1.classList.contains('fab-toggled')) {
        (
          document.querySelector('.fab-toggled').children[0]
            .children[0] as HTMLImageElement
        ).src = './assets/img/fab-blue-icon.svg';
      } else {
        (
          document.querySelector('#fabIcon').children[0] as HTMLImageElement
        ).src = './assets/img/fab-icon.svg';
      }
    }
  }

  async createProject() {
    this.createProjectClicked = true;
    await new Promise((resolve) => setTimeout(resolve, 2000));
    this.newProjectModal.show();
  }

  createOpportunity() {
    this.buttonsActions = {
      createOrder: false,
      createDeal: false,
      createOpp: true,
      createSOW: false,
      createPOC: false,
      createProposal: false,
      createCustReq: false,
      createEngRecom: false,
    };
    this.gwsGcpModal.showPopup();
    // this.goToPage(false);
  }

  createSOW() {
    this.buttonsActions = {
      createOrder: false,
      createDeal: false,
      createOpp: false,
      createSOW: true,
      createPOC: false,
      createProposal: false,
      createCustReq: false,
      createEngRecom: false,
    };
    // this.gwsGcpModal.showPopup();
    this.goToPage(false);
  }
  createPOC() {
    this.buttonsActions = {
      createOrder: false,
      createDeal: false,
      createOpp: false,
      createSOW: false,
      createPOC: true,
      createProposal: false,
      createCustReq: false,
      createEngRecom: false,
    };
    // this.gwsGcpModal.showPopup();
    this.goToPage(false);
  }
  createProposal() {
    this.buttonsActions = {
      createOrder: false,
      createDeal: false,
      createOpp: false,
      createSOW: false,
      createPOC: false,
      createProposal: true,
      createCustReq: false,
      createEngRecom: false,
    };
    // this.gwsGcpModal.showPopup();
    this.goToPage(false);
  }

  createCustReq() {
    this.buttonsActions = {
      createOrder: false,
      createDeal: false,
      createOpp: false,
      createSOW: false,
      createPOC: false,
      createProposal: false,
      createCustReq: true,
      createEngRecom: false,
    };
    //    this.gwsGcpModal.showPopup();
    this.goToPage(false);
  }

  createEngReco() {
    this.buttonsActions = {
      createOrder: false,
      createDeal: false,
      createOpp: false,
      createSOW: false,
      createPOC: false,
      createProposal: false,
      createCustReq: false,
      createEngRecom: true,
    };
    //   this.gwsGcpModal.showPopup();
    this.goToPage(false);
  }

  goToPage(isGWS: boolean) {
    this.localStorage.remove(Unicode.CURRENT_CUSTOMER);
    if (this.buttonsActions.createOrder) {
      this.router.navigate(['orders/add-order'], {
        queryParams: { isGWS: isGWS },
      });
    } else if (this.buttonsActions.createDeal) {
      if (isGWS) {
        this.router.navigate(['deals/add-deal'], {
          queryParams: {},
        });
      } else {
        this.router.navigate(['deals/add-deal-gcp'], {
          queryParams: {},
        });
      }
    } else if (this.buttonsActions.createOpp) {
      this.router.navigate(['opportunities/opportunity-details'], {
        state: { isGWS: isGWS },
      });
    } else if (this.buttonsActions.createSOW) {
      var params = new HttpParams().set(Unicode.IS_GCP, !isGWS);
      this.http
        .post(Endpoint.GLOBAL_URL + Endpoint.CREATE_SOW_GOOGLE_DOC, null, {
          params: params,
        })
        .toPromise()
        .then((resp: any) => {
          if (resp?.doc_id) {
            var sow = {
              google_doc_id: resp.doc_id,
              is_gcp: !isGWS,
            };
            this.router.navigate(['sow/sow-details'], {
              state: { sow: sow },
            });
          } else {
            alert(resp.message);
          }
        });
    } else if (this.buttonsActions.createPOC) {
      var params = new HttpParams().set(Unicode.IS_GCP, !isGWS);
      this.http
        .post(Endpoint.GLOBAL_URL + Endpoint.CREATE_POC_GOOGLE_DOC, null, {
          params: params,
        })
        .toPromise()
        .then((resp: any) => {
          if (resp?.doc_id) {
            var poc = {
              google_doc_id: resp.doc_id,
              is_gcp: !isGWS,
            };
            this.router.navigate(['poc/poc-details'], {
              state: { poc: poc },
            });
            this.router.navigate(['poc/poc-details'], {
              state: { poc: poc },
            });
          } else {
            alert(resp.message);
          }
        });
    } else if (this.buttonsActions.createProposal) {
      var params = new HttpParams().set(Unicode.IS_GCP, !isGWS);
      this.http
        .post(Endpoint.GLOBAL_URL + Endpoint.CREATE_PROPOSAL_GOOGLE_DOC, null, {
          params: params,
        })
        .toPromise()
        .then((resp: any) => {
          if (resp?.doc_id) {
            var proposal = {
              google_doc_id: resp.doc_id,
              is_gcp: !isGWS,
            };
            this.router.navigate(['proposal/proposal-details'], {
              state: { proposal: proposal },
            });
          } else {
            alert(resp.message);
          }
        });
    } else if (this.buttonsActions.createCustReq) {
      var params = new HttpParams().set(Unicode.IS_GCP, !isGWS);
      this.http
        .post(
          Endpoint.GLOBAL_URL +
            Endpoint.CREATE_CUSTOMER_REQUIREMENTS_GOOGLE_DOC,
          null,
          {
            params: params,
          }
        )
        .toPromise()
        .then((resp: any) => {
          if (resp?.doc_id) {
            var model = {
              google_doc_id: resp.doc_id,
              is_gcp: !isGWS,
            };
            this.router.navigate(['customer-requirements/details'], {
              state: { crModel: model },
            });
          } else {
            alert(resp.message);
          }
        });
    } else if (this.buttonsActions.createEngRecom) {
      var params = new HttpParams().set(Unicode.IS_GCP, !isGWS);
      this.http
        .post(
          Endpoint.GLOBAL_URL +
            Endpoint.CREATE_ENGINEER_RECOMMENDATION_GOOGLE_DOC,
          null,
          {
            params: params,
          }
        )
        .toPromise()
        .then((resp: any) => {
          if (resp?.doc_id) {
            var model = {
              google_doc_id: resp.doc_id,
              is_gcp: !isGWS,
            };
            this.router.navigate(['engineer-recommendations/details'], {
              state: { erModel: model },
            });
          } else {
            alert(resp.message);
          }
        });
    }
  }

  countUnreadNotifications() {
    this.http
      .get<any>(Endpoint.GLOBAL_URL + Endpoint.COUNT_UNREAD_NOTIFICATIONS)
      .subscribe(
        (response) => {
          // Extract the count from the response object
          const count = response.nb_unread_notifications;
          // Assuming you have a method to update the UI with the unread count
          this.messageService.setDropdownValue(count);
          this.count = count;
        },
        (error) => {
          console.error('Error marking notifications as read:', error);
        }
      );
  }
}
